<template>
  <div :class="`card card-product ${additionalItemClasses}`">
    <router-link :to="itemRoute">
      <div v-if="imageUrl" @click="" class="card-img-top p-2 mt-2">
        <img
          :alt="item.Description"
          :src="`${imageUrl}`"
          crossorigin="anonymous"
          class="rounded"
        />
      </div>
      <img v-else class="d-block mx-auto" src="/img/itemFallback.png" />

      <div
        v-if="item.Type === 'Composition' && !imageUrl"
        class="card-img-top p-2 mt-2"
      >
        <ImageGrid :images="compostionImages" />
      </div>
    </router-link>
    <div class="card-body text-left d-flex flex-column">
      <a v-if="item.Brand" class="product-brand" href="#">{{ item.Brand }}</a>
      <span class="product-title font-size-sm text-xl ">
        <router-link :to="itemRoute" class="text-gray-800">
          <strong class="text-primary"></strong>
          {{ item.Description }}
        </router-link>
      </span>
      <div class="content">
        <item-description
          :description="item.MediumDescription"
          :to="itemRoute"
        />
      </div>
      <div class="mt-2">
        <router-link
          :to="itemRoute"
          class="item-options btn bg-primary text-white"
        >
          Bekijk artikel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ImageGrid from '@/components/items/ImageGrid'
import ItemDescription from './ItemDescription'
// @ is an alias to /src
import { addToCart, getCart } from '@/services/CartService'

export default {
  name: 'Item',
  components: {
    ImageGrid,
    ItemDescription
  },
  props: {
    addToCartButton: {
      required: false,
      type: Boolean,
      default: function() {
        return false
      }
    },
    item: {
      type: Object,
      default: function() {
        return {}
      }
    },
    suggestedItem: {
      type: Boolean,
      required: false,
      default: function() {
        return false
      }
    }
  },
  data: function() {
    return {
      addToCartLoading: false
    }
  },
  computed: {
    cartCount: function() {
      return this.$store.state.cartCount
    },
    user: function() {
      return this.$store.getters.user
    },
    availableInWarehouse: function() {
      return true
      // if (this.$store.state.activeWarehouse === '') return true
      // if (this.item.WarehouseIDs?.includes(this.$store.state.activeWarehouse))
      //   return true
      // return false
    },
    additionalItemClasses: function() {
      if (!this.availableInWarehouse) {
        return 'unavailable'
      } else {
        return ''
      }
    },
    imageUrl() {
      if (this.item.Images?.length) {
        return `${process.env.VUE_APP_URL}${this.item.Images[0]}`
      } else {
        return null
      }
    },
    compostionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = []

        // get first image string from all items in this.item.Items
        for (let item of this.item.Items) {
          if (item.Images && item.Images.length > 0) {
            images.push(
              `${process.env.VUE_APP_URL}${item.Images[0]}&Format=Standard`
            )
          }
        }

        return images.slice(0, 4)
      }
      return null
    },
    itemRoute() {
      let name = 'uncategorizedItem'
      let params = { lang: this.$i18n.locale, itemID: this.item.ID }

      if (this.$route.params.categoryID) {
        name = 'categorizedItem'
        params.categoryID = this.$route.params.categoryID
      }

      if (this.$route.params.subCategoryID) {
        name = 'subCategorizedItem'
        params.subCategoryID = this.$route.params.subCategoryID
      }

      return {
        name,
        params
      }
    },
    price() {
      if (!this.item.ItemPeriods) return null
      if (this.item.ItemPeriods[1]) {
        return this.item.ItemPeriods[1].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      if (this.item.ItemPeriods[0]) {
        return this.item.ItemPeriods[0].PriceValue.toFixed(2)
          .toString()
          .replace('.', ',')
      }
      return null
    }
  },
  methods: {
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },

    addToCart: async function() {
      this.addToCartLoading = true
      let itemID = null
      let compositionID = null

      if (this.item.Type == 'Composition') {
        compositionID = this.item.ID
      } else {
        itemID = this.item.ID
      }
      const result = await addToCart({
        itemID,
        compositionID,
        amount: 1
      })

      if (result != undefined) {
        this.$toasted.show(
          `${this.$t('product')} ${this.itemData.Description} ${this.$t(
            'added-to-your-cart'
          )}`
        )
      }
      await getCart()

      this.addToCartLoading = false
    }
  }
}
</script>
<style scoped lang="scss">
.unavailable {
  filter: grayscale(100%) opacity(50%);
}
img {
  cursor: pointer;
}
.card {
  background-color: rgba(0, 0, 0, 0);
  img {
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    max-height: 100%;
    max-width: 100%;
  }
  br {
    display: none;
  }
  height: 100% !important;
  .buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .card-body {
    justify-content: flex-end;
  }
}

.card:after {
  margin-bottom: 10px;
}

.card-product:hover {
  border: 2px solid #f0f0f0;
  padding: 0px;
}
.card-product {
  margin-bottom: 2rem;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 1px;
}
.product-price {
  margin-top: -20px;
}
.content {
  min-height: 105px;
}

.text-accent {
  color: #4e54c8 !important;
}

.product-title {
  min-height: 50px;
  > a {
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }
}

h3 {
  font-size: 1.3rem !important;
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}

.card-img-top {
  min-height: 172px;

  padding: 1.25rem !important;
  justify-content: center;
  display: flex;

  div {
    display: block;

    img {
    }
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -ms-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
}
.fade-in {
  opacity: 1;
}
</style>
